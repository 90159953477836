import LIKE from './icons/like.svg';
import DISLIKE from './icons/dislike.svg';
import LOGO from './icons/logo.svg';

const ICONS = {
    LIKE,
    DISLIKE,
    LOGO
}


const IMG = { 
    ICONS
}

export default IMG;